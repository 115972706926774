//import { PyScriptLinkage } from "./types";

export const BUILD_VERSION = "126";

export interface RegistryRequest {
  address: string;
  code: string;
  mode: number;
  data: Record<string, any>;
}

export interface RegistryResponse {
  mode: number;
  typeName: string;
  preview?: any[][];
  logs?: logMessage[];
  size?: number[];
  result?: any;
  errorKind?: string;
  error?: string;
}

export interface PyScriptWorker {
  setRequest: (rndId: string, value: RegistryRequest) => Promise<void>;
  getResponse: (rndId: string, clear?: boolean) => Promise<RegistryResponse>;
  setCloudData: (address: string, data: any) => Promise<void>;
  delCloudData: (address: string) => Promise<void>;
  loadPackages: (pkgs: string[]) => Promise<string | null>;
  _runInit: (initCode: string) => Promise<runInitError | null>;
  resetKernel: (clearIsolated?: boolean) => Promise<void>;
  missingFromXLResults: (names: string[]) => Promise<string[]>;
  initialize: () => Promise<void>;
  runCode: (rndId: string) => Promise<void>;
}

export interface runInitError {
  typeName: string;
  error: string;
  errorKind: string;
  mode: number;
}

export interface WorkerInfo {
  worker: PyScriptWorker;
  terminate: () => void;
  loadingFlag: boolean;
  config: {} | (() => Promise<{}>);
}

export interface logMessage {
  type: "stdout" | "stderr";
  data: any;
}
