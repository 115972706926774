import { useEffect } from "react";
import { PlotType } from "../../../../data/plot-types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useChart } from "../../../../taskpane/hooks/plots/useCentralViz";
import { dependencyEqualsValue, dependencyNotEqualsValue, fetchHeaders } from "../MVCShared/PlotGeneratorUtils";
import { Chart, Orientation } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  PaletteAttr,
  CheckBoxAttr,
  SpinnerAttr,
  ColorPickerAttr,
  CollapsibleAttr,
  AxisDropdownAttr,
  LegendAttr,
  GridlinesAttr,
  SingleChartBorders,
  Origin,
} from "../MVCShared/PlotAttributes";
import { buildReactFromAttrs, buildCode, CodeBuilder } from "../MVCShared/CodeBuilder";
import { ViolinSetup, ViolinDesign as IViolinDesign } from "../../../../taskpane/hooks/plots/useViolin";

const ViolinContext = (): Chart => {
  const { setup, design, common, updateDesign, updateSetup, updateCommon, setCodeFragments } = useChart<
    ViolinSetup,
    IViolinDesign
  >(PlotType.violin);

  useEffect(() => {
    (async () => {
      const cb = await buildCode(common, [...ViolinChart.baseAttrs, ...ViolinChart.designAttrs]);
      setCodeFragments(cb);
    })();
  }, [setup, design, common]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(common.inputData, common.hasHeaders, updateSetup);
    })();
  }, [common.hasHeaders, common.inputData]);

  const orientationDropdown = DropdownAttr({
    value: setup.orientation,
    onChange: (_, data) => {
      const prevX: string = setup.xAxis;
      const prevY: string = setup.yAxis;
      updateSetup({
        orientation: data.optionValue,
        xAxis: prevY,
        yAxis: prevX,
      });
      const prevXAxisLabel: string = design.xAxisLabel;
      const prevYAxisLabel: string = design.yAxisLabel;
      updateDesign({
        xAxisLabel: prevYAxisLabel,
        yAxisLabel: prevXAxisLabel,
      });
    },
    label: "Orientation",
    options: ["Horizontal", "Vertical"],
    codeKey: "orient",
    codeValueMap: Orientation,
    codeRequiresInteraction: true,
  });

  const xAxis = AxisDropdownAttr({
    value: setup.xAxis,
    onChange: (_, data) => {
      updateSetup({ xAxis: data.optionValue }, "--Select--");
      updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
    },
    label: "X-Axis",
    options: ["--Select--", ...setup.headers],
    codeKey: "x",
    hasHeaders: common.hasHeaders,
  });

  const BandwidthAttr = DropdownAttr({
    value: setup.bandwidth,
    onChange: (_, data) => updateSetup({ bandwidth: data.optionValue }),
    label: "Bandwidth",
    options: ["Scott", "Silverman", "Custom"],
    codeKey: "bw_method",
    codeRequiresInteraction: true,
    dataTestID: "bandwidth",
  });

  BandwidthAttr.getCode = (code: CodeBuilder) => {
    if (setup.bandwidth.isUpdated === true) {
      setup.bandwidth.value === "Custom"
        ? null
        : code.plotAttrs.push(`bw_method="${setup.bandwidth.value.toLowerCase()}"`);
    }
  };

  // Specific rendering for custom
  const ColorBy = AxisDropdownAttr({
    value: setup.colorBy,
    onChange: (_, data) => updateSetup({ colorBy: data.optionValue }, "--None--"),
    label: "Color By",
    options: ["--None--", ...setup.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: common.hasHeaders,
  });

  const SaturationAttr = SpinnerAttr({
    label: "Saturation",
    value: design.saturation,
    step: 0.01,
    max: 1,
    onChange: (data: number) => updateDesign({ saturation: data }),
    codeKey: "saturation",
    codeRequiresInteraction: true,
  });

  const Legend = LegendAttr({
    value: design.legendPosition,
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "Best"),
  });

  let dodge = CheckBoxAttr({
    value: design.dodge,
    onChange: (_, e) => updateDesign({ dodge: e.checked }),
    label: "Overlapping groups",
    codeKey: "dodge",
    codeRequiresInteraction: true,
    enabledDependencies: [dependencyNotEqualsValue(ColorBy, "")],
    dataTestID: "dodge",
  });
  dodge.getCode = (code: CodeBuilder) => {
    if (design.dodge.isUpdated) {
      // Invert the value since the checkbox is for overlapping groups
      const dodge = design.dodge.value === true ? "False" : "True";
      code.plotAttrs.push(`dodge=${dodge}`);
    }
  };

  const alpha = SpinnerAttr({
    label: "Opacity",
    value: design.alpha,
    step: 0.1,
    min: 0,
    max: 1,
    onChange: (data: number) => updateDesign({ alpha: data }),
    codeKey: "alpha",
    dataTestID: "alpha",
    codeRequiresInteraction: true,
  });

  const origin = Origin({
    label: "Include Origin",
    yValue: setup.yLim,
    onChange: (_, e) => updateSetup({ yLim: e.checked }),
    codeRequiresInteraction: true,
  });

  const ViolinChart: Chart = {
    baseAttrs: [
      DataRangeAttr({
        inputData: common.inputData,
        onChangeSelection: (newSelection: RangeSelection) => updateCommon({ inputData: newSelection }),
      }),
      CheckBoxAttr({
        label: "Has headers",
        value: common.hasHeaders,
        onChange: (_, e) => updateCommon({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      orientationDropdown,
      DividerAttr(),
      HeadingAttr({
        title: "Data",
        tooltip: "Select data cells and parameters",
      }),
      xAxis,
      AxisDropdownAttr({
        value: setup.yAxis,
        onChange: (_, data) => {
          updateSetup({ yAxis: data.optionValue }, "--Select--");
          updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
        },
        label: "Y-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "y",
        hasHeaders: common.hasHeaders,
      }),
      origin,
      DividerAttr(),
      HeadingAttr({ title: "Grouping", tooltip: "Determine violin color" }),
      ColorBy,
      DividerAttr(),
      CollapsibleAttr({
        label: "Kernel",
        tooltip: "Compute kernel density estimate",
        collapsed: setup.isCollapsed,
        toggle: () => updateSetup({ isCollapsed: !setup.isCollapsed }),
        children: [
          BandwidthAttr,
          SpinnerAttr({
            label: "Custom Adjust",
            value: setup.custom,
            step: 0.1,
            min: 0.1,
            max: 10,
            onChange: (data: number) => updateSetup({ custom: data }),
            visibleDependencies: [dependencyEqualsValue(BandwidthAttr, "Custom")],
            codeKey: "bw_adjust",
            dataTestID: "bw-adjust",
          }),
          SpinnerAttr({
            label: "Cut",
            value: setup.cut,
            step: 1,
            onChange: (data: number) => updateSetup({ cut: data }),
            codeKey: "cut",
            codeRequiresInteraction: true,
            dataTestID: "cut",
          }),
          SpinnerAttr({
            label: "Grid Size",
            value: setup.gridSize,
            step: 10,
            onChange: (data: number) => updateSetup({ gridSize: data }),
            codeKey: "gridsize",
            codeRequiresInteraction: true,
            dataTestID: "grid",
          }),
        ],
      }),
      DividerAttr(),
      DropdownAttr({
        label: "Scale",
        options: ["Area", "Count", "Width"],
        value: setup.densityNorm,
        codeKey: "density_norm",
        codeValueMap: { Area: "area", Count: "count", Width: "width" },
        codeRequiresInteraction: true,
        onChange: (_, data) => updateSetup({ densityNorm: data.optionValue }),
      }),
      DividerAttr(),
    ],
    designAttrs: [
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Title",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "Defaults header",
        label: "X-Axis Label",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "Defaults header",
        label: "Y-Axis Label",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.borderCollapsed,
        label: "Border",
        toggle: () => {
          updateDesign({ borderCollapsed: !design.borderCollapsed });
        },
        children: [
          SingleChartBorders({
            label: "Top",
            value: design.topSpine,
            onChange: (_, e) => updateDesign({ topSpine: e.checked }),
            callKey: "top",
          }),
          SingleChartBorders({
            label: "Right",
            value: design.rightSpine,
            onChange: (_, e) => updateDesign({ rightSpine: e.checked }),
            callKey: "right",
          }),
          SingleChartBorders({
            label: "Bottom",
            value: design.bottomSpine,
            onChange: (_, e) => updateDesign({ bottomSpine: e.checked }),
            callKey: "bottom",
            lim: setup.yLim,
          }),
          SingleChartBorders({
            label: "Left",
            value: design.leftSpine,
            onChange: (_, e) => updateDesign({ leftSpine: e.checked }),
            callKey: "left",
          }),
        ],
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.gridlinesCollapsed,
        label: "Gridlines",
        toggle: () => {
          updateDesign({ gridlinesCollapsed: !design.gridlinesCollapsed });
        },
        children: [
          GridlinesAttr({
            majorHorizontal: design.majorHorizontal,
            majorVertical: design.majorVertical,
            minorHorizontal: design.minorHorizontal,
            minorVertical: design.minorVertical,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ xticks: data }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ yticks: data }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      ColorPickerAttr({
        value: design.color,
        label: "Color",
        onChange: (hexColor) => updateDesign({ color: `#${hexColor}` }),
        codeKey: "color",
        visibleDependencies: [dependencyEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      CheckBoxAttr({
        value: design.fill,
        onChange: (_, e) => updateDesign({ fill: e.checked }),
        label: "Fill",
        codeKey: "fill",
        codeRequiresInteraction: true,
      }),
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => updateDesign({ palette: data.optionText }),
        codeKey: "palette",
        placeholder: "Accent",
        visibleDependencies: [dependencyNotEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      SaturationAttr,
      DividerAttr(),
      HeadingAttr({ title: "Violin Formatting" }),
      SpinnerAttr({
        label: "Width",
        value: design.width,
        step: 0.1,
        onChange: (data: number) => updateDesign({ width: data }),
        codeKey: "width",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Line Width",
        value: design.lineWidth,
        step: 0.1,
        suffix: "px",
        onChange: (data: number) => updateDesign({ lineWidth: data }),
        codeKey: "linewidth",
        codeRequiresInteraction: true,
      }),
      CheckBoxAttr({
        value: design.split,
        onChange: (_, e) => updateDesign({ split: e.checked }),
        label: "Split",
        codeKey: "split",
        codeRequiresInteraction: true,
        dataTestID: "split",
      }),
      dodge,
      alpha,
      DropdownAttr({
        value: design.violinInner,
        onChange: (_, data) => updateDesign({ violinInner: data.optionValue }),
        label: "Violin Inner",
        options: ["Box", "Quartile", "Point", "Stick"],
        codeKey: "inner",
        codeValueMap: { Box: "box", Quartile: "quartile", Point: "point", Stick: "stick" },
        codeRequiresInteraction: true,
        dataTestID: "violin-inner",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend", tooltip: "Legend only visible for some combinations of inputs" }),
      Legend,
    ],
  };
  return ViolinChart;
};

const ViolinForm = () => {
  const ViolinChart = ViolinContext();
  return buildReactFromAttrs(ViolinChart.baseAttrs, 100);
};

export const ViolinDesign = () => {
  const violinDesign = ViolinContext();
  return buildReactFromAttrs(violinDesign.designAttrs, 120);
};

export default ViolinForm;
