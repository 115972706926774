export enum PyScriptOutput {
  Excel = 0, // Spill to Excel grid
  CodeObject = 1, // Card view
}

export enum PyScriptLinkage {
  ImportsAndDefinitions = -2, // No ability to reference other cells, used only in Imports and Definitions
  Linked = 0, // Run one cell runs them all, have access to local variables from previous cells
  Isolated = 2, // Each cell is independent, no access to local variables from other cells
}

export interface PyScriptMode {
  output: PyScriptOutput;
  linkage: PyScriptLinkage;
}

export enum PyScriptInterpreter {
  pyodide = "py",
  webr = "r",
}

export const RuntimeStatus = {
  NOT_STARTED: "Not Started",
  INITIALIZING: "Initializing...",
  LOADING_PYODIDE_PACKAGES: "Loading pyodide packages...",
  LOADING_MICROPIP_PACKAGES: "Loading micropip packages...",
  LOADING_PYSCRIPT_APPS: "Loading pyscript apps...",
  RUNNING_INIT_CODE: "Running imports and definitions...",
  RUNNING: "Running",
  ERROR: "Error",
};

export interface PyScriptSettings {
  defaultMode: number;
}

export enum SupportedDataTableActions {
  Delete = "delete",
  Deleted = "deleted",
  Add = "add",
  Added = "added",
  None = "none",
}

export type PackageRow = {
  package: string;
  version: string;
  // Manage request to add package state
  action?: SupportedDataTableActions;
};

export const viewColumns = [
  { columnKey: "package", label: "Package" },
  { columnKey: "version", label: "Version" },
];

export const columns = [
  { columnKey: "package", label: "Package" },
  { columnKey: "version", label: "Version" },
  { columnKey: "actions", label: "Actions" },
];

export type PackageDetails = {
  summary: string;
  home_page: string;
  authors: string;
  description: string;
  requires: string;
  maintainer_email: string;
  version: string;
  yanked: boolean;
};

export type PyodidePackages = {
  key: string;
  package: string;
  version: string;
};

export type PyodidePackageConflictRequest = {
  updated_version: string;
  current_version: string;
  packages: string[];
};

export enum PackageConflictType {
  Version = "Version",
  DoesNotExist = "DoesNotExist",
}

export type PyodidePackageConflict = {
  conflict_type: PackageConflictType;
  previous_version: string;
  // This is the package version associated with the newly selected pyodide version
  // This field is optional because the package may not exist in the updated pyodide version
  updated_version?: string;
};

// { [packageName]: PyodidePackageConflict, ... }
export type PyodidePackageConflictResponse = Record<string, PyodidePackageConflict>;

export enum ReturnFormat {
  List = "list",
  Dict = "dict",
}

export enum PackageSource {
  Url = "url",
  Pypi = "pypi",
  PyscriptApp = "pyscriptapp",
}
