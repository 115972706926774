import { useEffect } from "react";
import { PlotType } from "../../../../data/plot-types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useChart } from "../../../../taskpane/hooks/plots/useCentralViz";
import { Axes, Chart, Estimators, IntervalOptions, Markers, LineStyle, IntervalValueMap } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  CollapsibleAttr,
  ColorPickerAttr,
  CheckBoxAttr,
  SpinnerAttr,
  PaletteAttr,
  AxisDropdownAttr,
  LegendAttr,
  GridlinesAttr,
  SingleChartBorders,
  Origin,
} from "../MVCShared/PlotAttributes";
import { buildCode, buildReactFromAttrs, CodeBuilder } from "../MVCShared/CodeBuilder";
import { dependencyInValues, dependencyNotEqualsValue, fetchHeaders } from "../MVCShared/PlotGeneratorUtils";
import { LineSetup, LineDesign as ILineDesign } from "../../../../taskpane/hooks/plots/useLine";

const LineContext = (): Chart => {
  const { setup, design, common, updateDesign, updateSetup, updateCommon, setCodeFragments } = useChart<
    LineSetup,
    ILineDesign
  >(PlotType.line);

  useEffect(() => {
    (async () => {
      const cb = await buildCode(common, [...linePlot.baseAttrs, ...linePlot.designAttrs]);
      setCodeFragments(cb);
    })();
  }, [setup, design, common]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(common.inputData, common.hasHeaders, updateSetup);
    })();
  }, [common.hasHeaders, common.inputData]);

  const MarkerAttr = DropdownAttr({
    label: "Marker",
    value: design.marker,
    placeholder: "None",
    options: ["None", "Point", "Circle", "Plus", "Star", "Diamond", "X"],
    onChange: (_, e) => updateDesign({ marker: e.optionValue }, "None"),
    codeKey: "marker",
    codeValueMap: Markers,
    codeRequiresInteraction: true,
  });
  MarkerAttr.getCode = (code: CodeBuilder) => {
    if (design.marker !== "None") {
      code.plotAttrs.push(`marker='${Markers[design.marker]}', markeredgecolor='auto'`);
    }
  };

  const errorBar = DropdownAttr({
    value: setup.errorBar,
    label: "Error Bar",
    placeholder: "No error bars",
    onChange: (_, data) => updateSetup({ errorBar: data.optionValue }),
    codeRequiresInteraction: false,
    options: IntervalOptions,
  });
  errorBar.getCode = (code: CodeBuilder) => {
    // The intervals will override the errorbar attribute if set
    if (setup.errorBar === "No error bars") {
      code.plotAttrs.push("errorbar=None");
    }
  };

  const xAxis = AxisDropdownAttr({
    value: setup.xAxis,
    onChange: (_, data) => {
      updateSetup({ xAxis: data.optionValue }, "--Select--");
      updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
    },
    label: "X-Axis",
    options: ["--Select--", ...setup.headers],
    codeKey: "x",
    hasHeaders: common.hasHeaders,
  });

  const yAxis = AxisDropdownAttr({
    value: setup.yAxis,
    onChange: (_, data) => {
      updateSetup({ yAxis: data.optionValue }, "--Select--");
      updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
    },
    label: "Y-Axis",
    options: ["--Select--", ...setup.headers],
    codeKey: "y",
    hasHeaders: common.hasHeaders,
  });

  const ColorBy = AxisDropdownAttr({
    value: setup.colorBy,
    onChange: (_, data) => updateSetup({ colorBy: data.optionValue }, "--None--"),
    label: "Color By",
    options: ["--None--", ...setup.headers],
    visibleDependencies: [dependencyNotEqualsValue(xAxis, ""), dependencyNotEqualsValue(yAxis, "")],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: common.hasHeaders,
  });
  // Two intervals are used because they have different max values and initial setpoints
  // based on the error bar chosen. Only one will be shown at a time because the
  // visibleDependencies are mutually exclusive.
  const primaryInterval = SpinnerAttr({
    label: "Interval",
    value: setup.primaryInterval,
    step: 1,
    min: 0,
    max: 100,
    suffix: "%",
    onChange: (data: number) => updateSetup({ primaryInterval: data }),
    visibleDependencies: [dependencyInValues(errorBar, ["Confidence Interval", "Percentage Interval"])],
    enabledDependencies: [dependencyInValues(errorBar, ["Confidence Interval", "Percentage Interval"])],
    dataTestID: "interval",
  });
  primaryInterval.getCode = (code: CodeBuilder) => {
    code.plotAttrs.push(`errorbar=('${IntervalValueMap[setup.errorBar]}', ${setup.primaryInterval})`);
  };

  const secondaryInterval = SpinnerAttr({
    label: "Interval",
    value: setup.secondInterval,
    step: 1,
    min: 0,
    max: Infinity,
    suffix: "%",
    onChange: (data: number) => updateSetup({ secondInterval: data }),
    visibleDependencies: [dependencyInValues(errorBar, ["Standard Error", "Standard Deviation"])],
    enabledDependencies: [dependencyInValues(errorBar, ["Standard Error", "Standard Deviation"])],
  });
  secondaryInterval.getCode = (code: CodeBuilder) => {
    code.plotAttrs.push(`errorbar=('${IntervalValueMap[setup.errorBar]}', ${setup.secondInterval})`);
  };

  const error_kws = {};

  const errorBarColor = ColorPickerAttr({
    value: design.errorBarColor,
    label: "Error Bar Color",
    onChange: (hexColor) => updateDesign({ errorBarColor: `#${hexColor}` }),
  });

  error_kws["ecolor"] = design.errorBarColor.value;

  const errorBarThickness = SpinnerAttr({
    label: "Error Bar Thickness",
    value: design.errorBarWidth,
    onChange: (data: number) => updateDesign({ errorBarWidth: data }),
    suffix: "px",
    step: 1,
    min: 0,
    max: 2 ** 32 - 1,
    enabledDependencies: [dependencyNotEqualsValue(errorBar, "No error bars")],
    dataTestID: "capthick",
  });

  error_kws["capthick"] = design.errorBarWidth.value;

  const errorBarCapWidth = SpinnerAttr({
    label: "Error Bar Cap Width",
    value: design.errorBarCapWidth,
    step: 0.1,
    min: 0,
    max: 1,
    onChange: (data: number) => updateDesign({ errorBarCapWidth: data }),
    enabledDependencies: [dependencyNotEqualsValue(errorBar, "No error bars")],
    dataTestID: "capsize",
  });

  error_kws["capsize"] = design.errorBarCapWidth.value;

  const errorStyle = DropdownAttr({
    label: "Error Style",
    value: design.errorStyle,
    onChange: (_, data) => updateDesign({ errorStyle: data.optionValue }, "--Select--"),
    options: ["--Select--", "Bars"],
    codeKey: "err_style",
    enabledDependencies: [dependencyNotEqualsValue(errorBar, "None")],
    dataTestID: "error-style",
  });

  errorStyle.getCode = (code: CodeBuilder) => {
    if (design.errorStyle === "Bars") {
      code.plotAttrs.push(
        `err_style="bars", err_kws={${Object.entries(error_kws)
          .map(([key, value]) => `'${key}': ${JSON.stringify(value)}`)
          .join(", ")}}`
      );
    }
  };

  const Legend = LegendAttr({
    value: design.legendPosition,
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "Best"),
  });

  const linePlot: Chart = {
    baseAttrs: [
      DataRangeAttr({
        inputData: common.inputData,
        onChangeSelection: (newSelection: RangeSelection) => updateCommon({ inputData: newSelection }),
      }),
      CheckBoxAttr({
        label: "Has headers",
        value: common.hasHeaders,
        onChange: (_, e) => updateCommon({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Data", tooltip: "Select data cells and parameters" }),
      CheckBoxAttr({
        label: "Sort",
        value: setup.sort,
        onChange: (_, data) => updateSetup({ sort: data.checked }),
        codeKey: "sort",
        codeRequiresInteraction: true,
        dataTestID: "sort",
      }),
      xAxis,
      Origin({
        label: "Include Origin",
        value: setup.xLim,
        onChange: (_, e) => updateSetup({ xLim: e.checked }),
        codeRequiresInteraction: true,
      }),
      yAxis,
      Origin({
        label: "Include Origin",
        yValue: setup.yLim,
        onChange: (_, e) => updateSetup({ yLim: e.checked }),
        codeRequiresInteraction: true,
      }),
      DropdownAttr({
        value: setup.estimator,
        onChange: (_, data) => updateSetup({ estimator: data.optionValue }),
        label: "Estimator",
        options: ["Mean", "Median", "Max", "Min"],
        placeholder: "Mean",
        codeKey: "estimator",
        codeValueMap: Estimators,
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Grouping",
        tooltip: "Select parameters to determine line and marker color, size, and style",
      }),
      ColorBy,
      AxisDropdownAttr({
        value: setup.styleMarkersBy,
        onChange: (_, data) => updateSetup({ styleMarkersBy: data.optionValue }, "--None--"),
        label: "Style Markers By",
        options: ["--None--", ...setup.headers],
        placeholder: "--None--",
        codeKey: "markers",
        dataTestID: "styleByMarkers",
        hasHeaders: common.hasHeaders,
      }),
      AxisDropdownAttr({
        value: setup.styleLinesBy,
        onChange: (_, data) => updateSetup({ styleLinesBy: data.optionValue }, "--None--"),
        label: "Style Lines By",
        options: ["--None--", ...setup.headers],
        codeKey: "style",
        dataTestID: "styleByLines",
        hasHeaders: common.hasHeaders,
      }),
      AxisDropdownAttr({
        value: setup.sizeByLines,
        onChange: (_, data) => updateSetup({ sizeByLines: data.optionValue }, "--None--"),
        label: "Size Lines By",
        options: ["--None--", ...setup.headers],
        placeholder: "--None--",
        codeKey: "size",
        dataTestID: "styleBySize",
        hasHeaders: common.hasHeaders,
      }),
      DividerAttr(),
      CollapsibleAttr({
        label: "Error",
        tooltip: "Measure error in data",
        collapsed: setup.isCollapsed,
        toggle: () => updateSetup({ isCollapsed: !setup.isCollapsed }),
        children: [
          errorBar,
          primaryInterval,
          secondaryInterval,
          SpinnerAttr({
            label: "Bootstrap Samples",
            value: setup.bootstrapSamples,
            step: 100,
            min: 0,
            max: 2 ** 32 - 1,
            onChange: (data: number) => updateSetup({ bootstrapSamples: data }),
            codeKey: "n_boot",
            codeRequiresInteraction: true,
            visibleDependencies: [dependencyNotEqualsValue(errorBar, "No error bars")],
          }),
          SpinnerAttr({
            label: "Seed",
            value: setup.seed,
            step: 1,
            min: 0,
            max: 2 ** 32 - 1,
            onChange: (data: number) => updateSetup({ seed: data }),
            codeKey: "seed",
            codeRequiresInteraction: true,
            visibleDependencies: [dependencyNotEqualsValue(errorBar, "No error bars")],
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Axes",
        tooltip: "Set scale for axes",
      }),
      DropdownAttr({
        value: setup.xAxisScale,
        onChange: (_, data) => updateSetup({ xAxisScale: data.optionValue }),
        label: "X-Axis Scale",
        options: ["Linear", "Log"],
        placeholder: "Linear",
        callKey: "xscale",
        codeValueMap: Axes,
      }),
      DropdownAttr({
        value: setup.yAxisScale,
        onChange: (_, data) => updateSetup({ yAxisScale: data.optionValue }),
        label: "Y-Axis Scale",
        options: ["Linear", "Log"],
        placeholder: "Linear",
        callKey: "yscale",
        codeValueMap: Axes,
      }),
      DividerAttr(),
    ],
    designAttrs: [
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Title",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "Defaults header",
        label: "X-Axis Label",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "Defaults header",
        label: "Y-Axis Label",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.borderCollapsed,
        label: "Border",
        toggle: () => {
          updateDesign({ borderCollapsed: !design.borderCollapsed });
        },
        children: [
          SingleChartBorders({
            label: "Top",
            value: design.topSpine,
            onChange: (_, e) => updateDesign({ topSpine: e.checked }),
            callKey: "top",
          }),
          SingleChartBorders({
            label: "Right",
            value: design.rightSpine,
            onChange: (_, e) => updateDesign({ rightSpine: e.checked }),
            callKey: "right",
          }),
          SingleChartBorders({
            label: "Bottom",
            value: design.bottomSpine,
            onChange: (_, e) => updateDesign({ bottomSpine: e.checked }),
            lim: setup.yLim,
            callKey: "bottom",
          }),
          SingleChartBorders({
            label: "Left",
            value: design.leftSpine,
            onChange: (_, e) => updateDesign({ leftSpine: e.checked }),
            lim: setup.xLim,
            callKey: "left",
          }),
        ],
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.gridlinesCollapsed,
        label: "Gridlines",
        toggle: () => {
          updateDesign({ gridlinesCollapsed: !design.gridlinesCollapsed });
        },
        children: [
          GridlinesAttr({
            majorHorizontal: design.majorHorizontal,
            majorVertical: design.majorVertical,
            minorHorizontal: design.minorHorizontal,
            minorVertical: design.minorVertical,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ xticks: data }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ yticks: data }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Color" }),
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => updateDesign({ palette: data.optionText }),
        codeKey: "palette",
        placeholder: "Accent",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Markers" }),
      MarkerAttr,
      DividerAttr(),
      DropdownAttr({
        label: "Line Style",
        value: design.lineStyle,
        onChange: (_, data) => updateDesign({ lineStyle: data.optionValue }),
        options: ["Solid", "Dashed"],
        codeKey: "linestyle",
        codeValueMap: LineStyle,
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Line Width",
        value: design.lineWidth,
        onChange: (data: number) => updateDesign({ lineWidth: data }),
        suffix: "px",
        step: 1,
        min: 0,
        max: 2 ** 32 - 1,
        codeKey: "linewidth",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend", tooltip: "Legend only visible for some combinations of inputs" }),
      Legend,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isDesignCollapsed,
        toggle: () => updateDesign({ isDesignCollapsed: !design.isDesignCollapsed }),
        children: [
          DividerAttr(),
          HeadingAttr({ title: "Error" }),
          errorBarColor,
          errorStyle,
          errorBarThickness,
          errorBarCapWidth,
        ],
      }),
    ],
  };
  return linePlot;
};

const LineForm = () => {
  const LinePlot = LineContext();
  return buildReactFromAttrs(LinePlot.baseAttrs, 120);
};

export const LineDesign = () => {
  const LineDesign = LineContext();
  return buildReactFromAttrs(LineDesign.designAttrs, 140);
};

export default LineForm;
