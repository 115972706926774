import { getGlobal } from "../global";
import { PyScriptWorker, WorkerInfo, BUILD_VERSION } from "./worker";
import { RuntimeStatus } from "./types";
import { PyWorkerConfig } from "./pytypes";
import { PyScriptLinkage } from "./types";

const g = getGlobal();

export interface PyWorkerInfo extends WorkerInfo {
  worker: PyWorker;
  config: PyWorkerConfig | (() => Promise<PyWorkerConfig>);
}

export interface PyWorker extends PyScriptWorker {
  readStdout: () => Promise<string[]>;
  readStderr: () => Promise<string[]>;
  loadPyScriptApps: (pkgs: string[]) => Promise<string | null>;
  micropipInstall: (pkgs: string[]) => Promise<string | null>;
  runUDF: (
    rndId: string,
    xlName: string,
    isNested: boolean,
    funcName: string,
    address: string,
    args: any[]
  ) => Promise<void>;
  evalCode: (rndId: string, code: string, linkage: PyScriptLinkage) => Promise<void>;
}

export async function getPyWorker(
  info: PyWorkerInfo,
  setRuntimeStatusFn?: (status: string) => void,
  customInit?: (py: PyWorker) => Promise<void>
): Promise<PyWorker> {
  if (info.worker === undefined) {
    if (!info.loadingFlag) {
      // Grab the flag
      info.loadingFlag = true;
      // Wait for PyWorker to be available
      while (!g.PyWorker) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    } else {
      // Wait for 0.5 seconds and try again
      await new Promise((resolve) => setTimeout(resolve, 500));
      return await getPyWorker(info);
    }
    // Resolve any delayed settings
    if (typeof info.config === "function") {
      info.config = await info.config();
    }
    const rawWorker = await g.PyWorker(`/pyscript/static/pyworker/${BUILD_VERSION}/worker.py`, {
      version: info.config.pyodideVersion,
      config: {
        sync_main_only: true,
        files: {
          [`/pyscript/static/pyworker/${BUILD_VERSION}/utils.py`]: "",
          [`/pyscript/static/pyworker/${BUILD_VERSION}/udf.py`]: "",
        },
      },
    });
    info.terminate = () => rawWorker.terminate();
    const py: PyWorker = rawWorker.sync;

    // Pre-load packages
    await py.loadPackages(["setuptools"]);
    if (info.config.pyodidePackages?.length) {
      if (setRuntimeStatusFn) setRuntimeStatusFn(RuntimeStatus.LOADING_PYODIDE_PACKAGES);
      const error = await py.loadPackages(info.config.pyodidePackages);
      if (error) throw new Error(error);
    }

    if (info.config.pyscriptApps?.length) {
      if (setRuntimeStatusFn) setRuntimeStatusFn(RuntimeStatus.LOADING_PYSCRIPT_APPS);
      const error = await py.loadPyScriptApps(info.config.pyscriptApps);
      if (error) throw new Error(error);
    }

    if (info.config.micropipPackages?.length) {
      await py.loadPackages(["micropip"]);
      if (setRuntimeStatusFn) setRuntimeStatusFn(RuntimeStatus.LOADING_MICROPIP_PACKAGES);
      await py.micropipInstall(info.config.micropipPackages);
      const error = await py.micropipInstall(info.config.micropipPackages);
      if (error) throw new Error(error);
    }
    // Initialize
    if (setRuntimeStatusFn) setRuntimeStatusFn(RuntimeStatus.RUNNING_INIT_CODE);
    await py.initialize();
    if (customInit) {
      await customInit(py);
    } else {
      const error = await py._runInit(info.config.initCode);
      if (error) {
        const errorMessage = `Error Kind: ${error.errorKind}\nError Details: ${error.error}`;
        throw new Error(errorMessage);
      }
    }

    info.worker = py;
  }
  return info.worker;
}
