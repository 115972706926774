import { useEffect } from "react";
import { PlotType } from "../../../../data/plot-types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useChart } from "../../../../taskpane/hooks/plots/useCentralViz";
import { Axes, Chart, Markers } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  CollapsibleAttr,
  AxisDropdownAttr,
  CheckBoxAttr,
  SpinnerAttr,
  LegendAttr,
  PaletteAttr,
  GridlinesAttr,
  SingleChartBorders,
  paletteValueMap,
  Origin,
} from "../MVCShared/PlotAttributes";
import { buildCode, buildReactFromAttrs, CodeBuilder } from "../MVCShared/CodeBuilder";
import { fetchHeaders } from "../MVCShared/PlotGeneratorUtils";
import { ScatterSetup, ScatterDesign as IScatterDesign } from "../../../../taskpane/hooks/plots/useScatter";

const ScatterContext = (): Chart => {
  const { setup, design, common, updateDesign, updateSetup, updateCommon, setCodeFragments } = useChart<
    ScatterSetup,
    IScatterDesign
  >(PlotType.scatter);

  useEffect(() => {
    (async () => {
      const cb = await buildCode(common, [...scatterPlot.baseAttrs, ...scatterPlot.designAttrs]);
      setCodeFragments(cb);
    })();
  }, [setup, design, common]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(common.inputData, common.hasHeaders, updateSetup);
    })();
  }, [common.hasHeaders, common.inputData]);

  const MarkerAttr = DropdownAttr({
    label: "Marker",
    value: design.marker,
    options: ["Point", "Circle", "Plus", "Star", "Diamond", "X"],
    onChange: (_, e) => updateDesign({ marker: e.optionValue }),
    codeKey: "marker",
    codeRequiresInteraction: true,
    dataTestID: "marker",
  });

  MarkerAttr.getCode = (code: CodeBuilder) => {
    if (design.marker.isUpdated) {
      code.plotAttrs.push(
        `marker="${Markers[design.marker.value]}", color=sns.color_palette('${
          paletteValueMap[design.palette.value]
        }')[0]`
      );
    }
  };

  const Legend = LegendAttr({
    value: design.legendPosition,
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "Best"),
  });

  const scatterPlot: Chart = {
    baseAttrs: [
      DataRangeAttr({
        inputData: common.inputData,
        onChangeSelection: (newSelection: RangeSelection) => updateCommon({ inputData: newSelection }),
      }),
      CheckBoxAttr({
        label: "Has headers",
        value: common.hasHeaders,
        onChange: (_, e) => updateCommon({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Data", tooltip: "Select data cells and parameters" }),
      AxisDropdownAttr({
        value: setup.xAxis,
        onChange: (_, data) => {
          updateSetup({ xAxis: data.optionValue }, "--Select--");
          updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
        },
        label: "X-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "x",
        hasHeaders: common.hasHeaders,
      }),
      Origin({
        label: "Include Origin",
        value: setup.xLim,
        onChange: (_, e) => updateSetup({ xLim: e.checked }),
        codeRequiresInteraction: true,
      }),
      AxisDropdownAttr({
        value: setup.yAxis,
        onChange: (_, data) => {
          updateSetup({ yAxis: data.optionValue }, "--Select--");
          updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
        },
        label: "Y-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "y",
        hasHeaders: common.hasHeaders,
      }),
      Origin({
        label: "Include Origin",
        yValue: setup.yLim,
        onChange: (_, e) => updateSetup({ yLim: e.checked }),
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Grouping", tooltip: "Select parameters to determine point color, size, and style" }),
      AxisDropdownAttr({
        value: setup.colorBy,
        onChange: (_, data) => updateSetup({ colorBy: data.optionValue }, "--None--"),
        label: "Color By",
        options: ["--None--", ...setup.headers],
        placeholder: "--None--",
        codeKey: "hue",
        hasHeaders: common.hasHeaders,
      }),
      AxisDropdownAttr({
        value: setup.sizeBy,
        onChange: (_, data) => updateSetup({ sizeBy: data.optionValue }, "--None--"),
        label: "Size By",
        options: ["--None--", ...setup.headers],
        placeholder: "--None--",
        codeKey: "size",
        hasHeaders: common.hasHeaders,
      }),
      AxisDropdownAttr({
        value: setup.styleBy,
        onChange: (_, data) => updateSetup({ styleBy: data.optionValue }, "--None--"),
        label: "Style By",
        options: ["--None--", ...setup.headers],
        placeholder: "--None--",
        codeKey: "style",
        hasHeaders: common.hasHeaders,
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Axes",
        tooltip: "Set scale for axes",
      }),
      DropdownAttr({
        value: setup.newXAxisScale,
        onChange: (_, data) => updateSetup({ newXAxisScale: data.optionValue }),
        label: "X-Axis Scale",
        options: ["Linear", "Log"],
        callKey: "xscale",
        codeValueMap: Axes,
        codeRequiresInteraction: true,
        dataTestID: "x-axis-scale",
      }),
      DropdownAttr({
        value: setup.newYAxisScale,
        onChange: (_, data) => updateSetup({ newYAxisScale: data.optionValue }),
        label: "Y-Axis Scale",
        options: ["Linear", "Log"],
        callKey: "yscale",
        codeValueMap: Axes,
        codeRequiresInteraction: true,
        dataTestID: "y-axis-scale",
      }),
      DividerAttr(),
    ],
    designAttrs: [
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Title",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "Defaults header",
        label: "X-Axis Label",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "Defaults header",
        label: "Y-Axis Label",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.borderCollapsed,
        label: "Border",
        toggle: () => {
          updateDesign({ borderCollapsed: !design.borderCollapsed });
        },
        children: [
          SingleChartBorders({
            label: "Top",
            value: design.topSpine,
            onChange: (_, e) => updateDesign({ topSpine: e.checked }),
            callKey: "top",
          }),
          SingleChartBorders({
            label: "Right",
            value: design.rightSpine,
            onChange: (_, e) => updateDesign({ rightSpine: e.checked }),
            callKey: "right",
          }),
          SingleChartBorders({
            label: "Bottom",
            value: design.bottomSpine,
            onChange: (_, e) => updateDesign({ bottomSpine: e.checked }),
            callKey: "bottom",
            lim: setup.yLim,
          }),
          SingleChartBorders({
            label: "Left",
            value: design.leftSpine,
            onChange: (_, e) => updateDesign({ leftSpine: e.checked }),
            callKey: "left",
            lim: setup.xLim,
          }),
        ],
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.gridlinesCollapsed,
        label: "Gridlines",
        toggle: () => {
          updateDesign({ gridlinesCollapsed: !design.gridlinesCollapsed });
        },
        children: [
          GridlinesAttr({
            majorHorizontal: design.majorHorizontal,
            majorVertical: design.majorVertical,
            minorHorizontal: design.minorHorizontal,
            minorVertical: design.minorVertical,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ xticks: data }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ yticks: data }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Color" }),
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => {
          updateDesign({ palette: data.optionText });
        },
        codeKey: "palette",
        placeholder: "Accent",
        // visibleDependencies: [dependencyEqualsValue(fillStyle, "Palette")],
        codeRequiresInteraction: true,
        dataTestID: "palette",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Markers" }),
      MarkerAttr,
      DividerAttr(),
      HeadingAttr({ title: "Legend", tooltip: "Legend only visible for some combinations of inputs" }),
      Legend,
    ],
  };
  return scatterPlot;
};

const ScatterForm = () => {
  const scatterPlot = ScatterContext();
  return buildReactFromAttrs(scatterPlot.baseAttrs, 100);
};

export const ScatterDesign = () => {
  const scatterDesign = ScatterContext();
  return buildReactFromAttrs(scatterDesign.designAttrs, 120);
};

export default ScatterForm;
