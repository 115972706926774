import React from "react";
import { Divider, Tooltip, Checkbox } from "@fluentui/react-components";
import { Info12Regular } from "@fluentui/react-icons";
import { CustomDropdown, DesignInputs, CollapsibleSection } from "../VizSharedComponents/VizUIShared";
import { ChartAttr, UIAttr, Dependency, areDependenciesSatisfied, ValueTracker } from "./types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import TableChooser from "../../../../excel/components/TableChooser";
import { Row } from "../../../components/Layout/Space";
import { BaseCommon } from "../../../hooks/plots/PlotTypes";
import { PaletteDropdown } from "../VizSharedComponents/Palette";
import { Spinner } from "../VizSharedComponents/SpinButton";
import { LegendPosition } from "./types";
import VizColorPicker from "../VizSharedComponents/ColorPicker";
import { buildReactFromAttrs, buildCodeFromAttrs, CodeBuilder } from "./CodeBuilder";
import { snakeEyesRecord } from "../../../../analytics/snake-eyes";

/****************
 * UIAttr Constructors
 ****************/
export function DividerAttr(): UIAttr {
  return {
    getReact: () => {
      return <Divider />;
    },
  };
}

type HeadingArgs = {
  title: string;
  tooltip?: string;
  visibleDependencies?: Dependency[];
};

export function HeadingAttr(args: HeadingArgs): UIAttr {
  const { title, tooltip, visibleDependencies } = args;

  const headingLabel = tooltip ? (
    <div style={{ marginTop: 5 }}>
      <label className="label" style={{ marginRight: "5px" }}>
        {title}
      </label>
      <Tooltip content={tooltip} positioning="after" relationship="label" withArrow={true}>
        <Info12Regular />
      </Tooltip>
    </div>
  ) : (
    <div style={{ marginTop: 5 }}>
      <label className="label">{title}</label>
    </div>
  );

  return {
    visibleDependencies: visibleDependencies,
    getReact: () => {
      return <>{headingLabel}</>;
    },
  };
}

export type DropdownArgs = {
  value?: string | ValueTracker<string> | string[];
  onChange: (event, data) => void;
  label?: string;
  options?: string[];
  placeholder?: string;
  codeKey?: string;
  callKey?: string;
  codeValueMap?: Record<string, string>;
  multiselect?: boolean;
  selectedOptons?: string[];
  visibleDependencies?: Dependency[];
  enabledDependencies?: Dependency[];
  codeRequiresInteraction?: boolean;
  dataTestID?: string;
};

export function DropdownAttr(args: DropdownArgs): ChartAttr {
  const { value, placeholder = "--Select-- ", codeRequiresInteraction } = args;
  let val = value instanceof ValueTracker ? value.value : (value as string);
  return {
    component: val,
    visibleDependencies: args.visibleDependencies,
    enabledDependencies: args.enabledDependencies,
    getReact: (labelWidth: number) => {
      return (
        <CustomDropdown
          dataTestID={args.dataTestID}
          label={args.label}
          options={args.options}
          placeholder={placeholder}
          value={val}
          labelWidth={labelWidth}
          onChange={args.onChange}
          multiselect={args.multiselect}
          isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
          selectedOptions={args.selectedOptons}
        />
      );
    },
    getCode: (code: CodeBuilder) => {
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker))
          throw Error("DropdownAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      let selections = "";

      if (Array.isArray(val)) {
        val.forEach((selection) => {
          selections += `"${selection}", `;
        });

        if (selections.length > 1) {
          code.plotAttrs.push(`${args.codeKey}=[${selections}]`);
        }
      } else {
        let codeValue = args.codeValueMap ? args.codeValueMap[val] : val;
        codeValue = (codeValue as string) ?? codeValue?.replaceAll("'", "\\'"); // escape single quotes

        if (codeValue && args.codeKey) {
          code.plotAttrs.push(`${args.codeKey}=${codeValue === "None" ? codeValue : `'${codeValue}'`}`);
        }

        if (codeValue && args.callKey) {
          code.plotCalls.push(`plt.${args.callKey}(${codeValue === "None" ? codeValue : `'${codeValue}'`})`);
        }
      }
    },
  };
}

export type AxisDropdown = DropdownArgs & {
  hasHeaders: boolean;
};

export function MultiSelectDropdownAttr(args: AxisDropdown): ChartAttr {
  const { value } = args;
  let val = value instanceof ValueTracker ? value.value : (value as any);
  return {
    component: val,
    visibleDependencies: args.visibleDependencies,
    enabledDependencies: args.enabledDependencies,
    getReact: (labelWidth: number) => {
      return (
        <CustomDropdown
          dataTestID={args.dataTestID}
          label={args.label}
          labelWidth={labelWidth}
          options={args.options}
          placeholder="--Select-- "
          value={val}
          onChange={args.onChange}
          multiselect={args.multiselect}
          selectedOptions={args.selectedOptons}
          isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
        />
      );
    },
    getCode: (code: CodeBuilder) => {
      let selections = "";
      if (Array.isArray(val)) {
        if (!args.hasHeaders) {
          val.forEach((selection) => {
            let column = `${parseInt(selection.split(" ")[1]) - 1}, `;
            selections += `${column}`;
          });
        } else {
          val.forEach((selection) => {
            selections += `"${selection}", `;
          });
        }
        if (selections.length > 1) {
          code.plotAttrs.push(`${args.codeKey}=[${selections}]`);
        }
      }
    },
  };
}

export function AxisDropdownAttr(args: AxisDropdown): ChartAttr {
  const { value, onChange, placeholder = "--Select--", codeRequiresInteraction } = args;
  let val = value instanceof ValueTracker ? value.value : (value as string);
  return {
    component: val,
    visibleDependencies: args.visibleDependencies,
    enabledDependencies: args.enabledDependencies,
    getReact: (labelWidth: number) => {
      return (
        <CustomDropdown
          dataTestID={args.dataTestID}
          label={args.label}
          labelWidth={labelWidth}
          options={args.options}
          value={val}
          onChange={onChange}
          placeholder={placeholder}
          multiselect={args.multiselect}
          isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
        />
      );
    },
    getCode: (code: CodeBuilder) => {
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker))
          throw Error("AxisDropdownAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      let codeValue = args.codeValueMap ? args.codeValueMap[val] : val;
      if (!args.hasHeaders && codeValue) {
        const column = parseInt(codeValue.split(" ")[1]);
        code.plotAttrs.push(`${args.codeKey}=${column - 1}`);
      } else {
        codeValue && args.codeKey ? code.plotAttrs.push(`${args.codeKey}='${codeValue}'`) : "";
      }
    },
  };
}

export type LabelArgs = DropdownArgs & {
  posKey?: string;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
};

export function LabelAttr(args: LabelArgs): ChartAttr {
  const { value, onChange: onInput, codeRequiresInteraction } = args;
  let val = value instanceof ValueTracker ? value.value : (value as string);
  return {
    component: val,
    visibleDependencies: args.visibleDependencies,
    enabledDependencies: args.enabledDependencies,
    getReact: (labelWidth: number) => {
      return (
        <DesignInputs
          dataTestID={args.dataTestID}
          label={args.label}
          labelWidth={labelWidth}
          value={val}
          onInput={onInput}
          placeholder={args.placeholder}
          isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
        />
      );
    },
    getCode: (code: CodeBuilder) => {
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker)) throw Error("LabelAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      // TODO: change this to callKey and add new line to handle codeKey
      // TODO: review all the other ChartAttrs and unify the usage of codeKey, callKey, codeValueMap
      val = val.replaceAll("'", "\\'"); // Escape single quotes
      args.posKey && val ? code.plotAttrs.push(`${args.posKey}='${val}'`) : "";
      args.codeKey && val ? code.plotCalls.push(`plt.${args.codeKey}('${val}')`) : "";
    },
  };
}

type DataRangeArgs = {
  inputData: BaseCommon["inputData"];
  onChangeSelection: (newSelection: RangeSelection) => void;
  visibleDependencies?: Dependency[];
};

export function DataRangeAttr(args: DataRangeArgs): UIAttr {
  const { inputData, onChangeSelection, visibleDependencies } = args;
  return {
    visibleDependencies: visibleDependencies,
    getReact: (labelWidth: number) => {
      return (
        <Row justifyContent="space-between">
          <label className="label" style={{ minWidth: labelWidth }}>
            Data
          </label>
          <TableChooser
            width="100%"
            size="small"
            placeholder="Select"
            selection={inputData}
            onSelection={(newSelection) => {
              onChangeSelection(newSelection);
              snakeEyesRecord({
                event: "viz/range_selected",
              });
            }}
          />
        </Row>
      );
    },
  };
}

type CollapsibleArgs = {
  visibleDependencies?: Dependency[];
  children?: UIAttr[];
  codeKey?: string;
  val?: string;
  codeValueMap?: Record<string, string>;
  collapsed?: boolean;
  toggle?: () => void;
  label?: string;
  tooltip?: string;
};

export function CollapsibleAttr(args: CollapsibleArgs): UIAttr {
  const { visibleDependencies, children, collapsed, toggle, label, tooltip } = args;
  return {
    visibleDependencies: visibleDependencies,
    getReact: (labelWidth: number) => {
      return (
        <>
          <CollapsibleSection isCollapsed={collapsed} toggleCollapse={toggle} label={label} tooltip={tooltip}>
            {buildReactFromAttrs(children, labelWidth)}
          </CollapsibleSection>
        </>
      );
    },
    getCode: (cb: CodeBuilder) => {
      buildCodeFromAttrs(children, cb);
    },
  };
}

export const paletteValueMap = {
  Accent: "Accent",
  Blues: "Blues",
  BrBG: "BrBG",
  Blues_d: "Blues_d",
  Tab10: "tab10",
};

export function PaletteAttr(args: DropdownArgs): ChartAttr {
  const {
    value,
    onChange,
    codeKey,
    visibleDependencies,
    codeRequiresInteraction,
    enabledDependencies,
    label = "Palette",
  } = args;
  let val = value instanceof ValueTracker ? value.value : (value as string);

  return {
    component: val,
    visibleDependencies: visibleDependencies,
    enabledDependencies: enabledDependencies,
    getReact: (labelWidth: number) => {
      return (
        <PaletteDropdown
          dataTestID={args.dataTestID}
          value={val}
          onChange={onChange}
          disabled={!areDependenciesSatisfied(enabledDependencies)}
          label={label}
          labelWidth={labelWidth}
        />
      );
    },
    getCode: (code: CodeBuilder) => {
      const paletteValue = paletteValueMap[val];
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker))
          throw Error("PalleteAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      codeKey ? code.plotAttrs.push(`${codeKey}='${paletteValue}'`) : null;
    },
  };
}

type CheckboxArgs = {
  value?: boolean | ValueTracker<boolean>;
  onChange: (_, event) => void;
  label: string;
  codeKey?: string;
  visibleDependencies?: Dependency[];
  enabledDependencies?: Dependency[];
  codeRequiresInteraction?: boolean;
  dataTestID?: string;
};

export function CheckBoxAttr(args: CheckboxArgs): ChartAttr {
  const { value, onChange, label, codeKey, codeRequiresInteraction } = args;
  let val = value instanceof ValueTracker ? value.value : value;
  return {
    component: val,
    visibleDependencies: args.visibleDependencies || [],
    enabledDependencies: args.enabledDependencies,
    getReact: (labelWidth: number) => {
      return (
        <Row justifyContent="space-between">
          <label className="label" style={{ minWidth: labelWidth }}>
            {label}
          </label>
          <Checkbox
            style={{ width: "100%" }}
            checked={val}
            onChange={onChange}
            disabled={!areDependenciesSatisfied(args.enabledDependencies)}
            data-testid={args.dataTestID}
          />
        </Row>
      );
    },
    getCode: (code: CodeBuilder) => {
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker))
          throw Error("CheckBoxAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      let sval: string = val ? "True" : "False";
      codeKey ? code.plotAttrs.push(`${codeKey}=${sval}`) : null;
    },
  };
}

type SpinnerArgs = {
  value?: number | ValueTracker<number>;
  suffix?: string;
  onChange: (value: number) => void;
  label: string;
  step: number;
  max?: number;
  min?: number;
  codeKey?: string;
  callKey?: string;
  visibleDependencies?: Dependency[];
  enabledDependencies?: Dependency[];
  codeRequiresInteraction?: boolean;
  dataTestID?: string;
};

export function SpinnerAttr(args: SpinnerArgs): ChartAttr {
  const {
    value,
    onChange,
    label,
    visibleDependencies,
    enabledDependencies,
    codeKey,
    callKey,
    step,
    max = 2 ** 32 - 1,
    min = 0,
    codeRequiresInteraction,
  } = args;
  let val = value instanceof ValueTracker ? value.value : value;
  return {
    component: val,
    visibleDependencies: visibleDependencies,
    enabledDependencies: enabledDependencies,
    getReact: (labelWidth: number) => {
      return (
        <Spinner
          label={label}
          labelWidth={labelWidth}
          value={val}
          onChange={(newValue: number) => onChange(newValue)}
          step={step}
          max={max}
          min={min}
          units={args.suffix}
          dataTestID={args.dataTestID}
          isDisabled={!areDependenciesSatisfied(enabledDependencies)}
        />
      );
    },
    getCode: (code: CodeBuilder) => {
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker))
          throw Error("SpinnerAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      codeKey ? code.plotAttrs.push(`${codeKey}=${val}`) : null;
      callKey ? code.plotCalls.push(`plt.${callKey}(rotation=${val})`) : null;
    },
  };
}

type ColorPickerArgs = {
  value: string | ValueTracker<string>;
  onChange: (_, event) => void;
  codeKey?: string;
  visibleDependencies?: Dependency[];
  label: string;
  codeRequiresInteraction?: boolean;
};

export function ColorPickerAttr(args: ColorPickerArgs): ChartAttr {
  const { value, onChange, visibleDependencies, codeKey, label, codeRequiresInteraction } = args;
  let val = value instanceof ValueTracker ? value.value : value;
  return {
    component: val,
    visibleDependencies: visibleDependencies,
    getReact: (labelWidth: number) => {
      return <VizColorPicker label={label} labelWidth={labelWidth} defaultColor={val} onChange={onChange} />;
    },
    getCode: (code: CodeBuilder) => {
      if (codeRequiresInteraction) {
        if (!(value instanceof ValueTracker))
          throw Error("ColorPickerAttr must use ValueTracker if codeRequiresInteraction");
        if (!value.isUpdated) return;
      }
      codeKey ? code.plotAttrs.push(`${codeKey}='${val}'`) : "";
    },
  };
}

type LineKwargsArgs = {
  dropdownValue: string | ValueTracker<string>;
  onChangeStyle: (_, event) => void;
  styleLabel: string;
  options?: string[];
  codeValueMap?: Record<string, string>;
};

export function LineKwargs(args: SpinnerArgs & LineKwargsArgs): ChartAttr {
  let val = args.value instanceof ValueTracker ? args.value.value : args.value;
  let dropdownVal = args.dropdownValue instanceof ValueTracker ? args.dropdownValue.value : args.dropdownValue;
  return {
    component: val,
    visibleDependencies: args.visibleDependencies,
    enabledDependencies: args.enabledDependencies,
    getReact: (labelWidth: number) => {
      return (
        <>
          <CustomDropdown
            label={args.styleLabel}
            labelWidth={labelWidth}
            options={args.options}
            value={dropdownVal}
            onChange={args.onChangeStyle}
            isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
          />
          <Spinner
            label={args.label}
            labelWidth={labelWidth}
            value={val}
            onChange={(newValue: number) => args.onChange(newValue)}
            step={args.step}
            units={args.suffix}
            dataTestID={args.dataTestID}
            isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
          />
        </>
      );
    },
    getCode: (code: CodeBuilder) => {
      function checkValueAndUpdate(args, propName) {
        if (!(args[propName] instanceof ValueTracker)) {
          throw new Error(`${propName} must use ValueTracker if codeRequiresInteraction`);
        }
        if (!args[propName].isUpdated) {
          return false;
        }
        return true;
      }
      if (args.codeRequiresInteraction) {
        if (!checkValueAndUpdate(args, "value") && !checkValueAndUpdate(args, "dropdownValue")) return;
      }
      const lineKws = {};
      dropdownVal ? (lineKws["ls"] = args.codeValueMap[dropdownVal]) : "";
      val ? (lineKws["lw"] = val) : "";
      Object.keys(lineKws).length > 0
        ? code.plotAttrs.push(
            `line_kws={${Object.entries(lineKws)
              .map(([key, value]) => `'${key}': ${JSON.stringify(value)}`)
              .join(", ")}}`
          )
        : null;
    },
  };
}

type errorBarSpinner = {
  spinnerValue: number | ValueTracker<number>;
  decimalValue: number | ValueTracker<number>;
  suffixLabel: string;
  decimalLabel: string;
  suffixStep: number;
  decimalStep: number;
  max?: number;
  suffix?: string;
  xAxis: string;
  yAxis: string;
  visibleDependencies?: Dependency[];
  enabledDependencies?: Dependency[];
  codeRequiresInteraction?: boolean;
  onValueChange: (value: number) => void;
  onDecimalChange: (value: number) => void;
};

export function regressionErrorBar(args: ColorPickerArgs & errorBarSpinner): ChartAttr {
  const val = args.value instanceof ValueTracker ? args.value.value : args.value;
  const suffixVal = args.spinnerValue instanceof ValueTracker ? args.spinnerValue.value : args.spinnerValue;
  const decimalVal = args.decimalValue instanceof ValueTracker ? args.decimalValue.value : args.decimalValue;
  return {
    component: "Error Bar",
    visibleDependencies: args.visibleDependencies,
    enabledDependencies: args.enabledDependencies,
    getReact: (labelWidth: number) => {
      return (
        <>
          <VizColorPicker defaultColor={val} label={args.label} labelWidth={labelWidth} onChange={args.onChange} />
          <Spinner
            label={args.suffixLabel}
            labelWidth={labelWidth}
            value={suffixVal}
            onChange={(newValue: number) => args.onValueChange(newValue)}
            step={args.suffixStep}
            units={args.suffix}
            isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
            dataTestID="errwidth"
          />
          <Spinner
            label={args.decimalLabel}
            labelWidth={labelWidth}
            value={decimalVal}
            onChange={(newValue: number) => args.onDecimalChange(newValue)}
            step={args.decimalStep}
            isDisabled={!areDependenciesSatisfied(args.enabledDependencies)}
            dataTestID="capsize"
          />
        </>
      );
    },
    getCode: (code: CodeBuilder) => {
      let start = `ax.errorbar(x="${args.xAxis}", y="${args.yAxis}"`;
      let anyUpdated = false;

      if (args.codeRequiresInteraction) {
        if (args.value instanceof ValueTracker && args.value.isUpdated) {
          start += `, ecolor='${val}'`;
          anyUpdated = true;
        }

        if (args.spinnerValue instanceof ValueTracker && args.spinnerValue.isUpdated) {
          start += `, capthick=${suffixVal}`;
          anyUpdated = true;
        }

        if (args.decimalValue instanceof ValueTracker && args.decimalValue.isUpdated) {
          start += `, capsize=${decimalVal}`;
          anyUpdated = true;
        }
      }
      if (anyUpdated && args.xAxis && args.yAxis) {
        start += ")";
        code.plotCalls.push(start);
      }
    },
  };
}

export function LegendAttr(args: DropdownArgs): ChartAttr {
  const { value, onChange, visibleDependencies, enabledDependencies, label = "Legend Position" } = args;
  let val = value instanceof ValueTracker ? value.value : (value as string);
  return {
    component: val,
    visibleDependencies: visibleDependencies,
    enabledDependencies: enabledDependencies,
    getReact: (labelWidth: number) => {
      return (
        <>
          <CustomDropdown
            label={label}
            labelWidth={labelWidth}
            placeholder="Best"
            options={["Best", "No Legend"]}
            groupedOptions={{
              Outside: ["Top", "Top (3 Columns)", "Right"],
              Inside: [
                "Upper Left",
                "Upper Center",
                "Upper Right",
                "Middle Left",
                "Middle Center",
                "Middle Right",
                "Lower Left",
                "Lower Center",
                "Lower Right",
              ],
            }}
            value={val}
            onChange={onChange}
            isDisabled={!areDependenciesSatisfied(enabledDependencies)}
          />
        </>
      );
    },
    getCode: (code: CodeBuilder) => {
      if (val === "No Legend") {
        code.plotAttrs.push("legend=False");
      } else if (val in LegendPosition) {
        const { location, extra } = LegendPosition[val];
        code.plotCalls.push(`if ax.get_legend():\n    sns.move_legend(ax, '${location}'${extra ?? ""})`);
      } else {
        console.log(`Unexpected legend placement: ${val}`);
      }
    },
  };
}

type ErrorKwsArgs = {
  value: ValueTracker<string>;
  spinnerValue: ValueTracker<number>;
  onValueChange: (value: number) => void;
  colorValue: string | ValueTracker<string>;
  enabledDependencies?: Dependency[];
  onChange: (value) => void;
  spinnerReqiuiresInteraction: boolean;
  colorRequiresInteraction: boolean;
};

export function ErrorKws(args: ErrorKwsArgs): ChartAttr {
  const { onChange, enabledDependencies, onValueChange } = args;

  let val = args.value instanceof ValueTracker ? args.value.value : (args.value as string);
  let spinnerValue = args.spinnerValue instanceof ValueTracker ? args.spinnerValue.value : args.spinnerValue;

  return {
    component: val,
    enabledDependencies: enabledDependencies,
    getReact: (labelWidth: number) => {
      return (
        <>
          <VizColorPicker label="Error Color" labelWidth={labelWidth} defaultColor={val} onChange={onChange} />
          <Spinner
            label="Error Width"
            labelWidth={labelWidth}
            value={spinnerValue}
            onChange={(value: number) => onValueChange(value)}
            min={0}
            max={10}
            step={1}
            units=" px"
            isDisabled={!areDependenciesSatisfied(enabledDependencies)}
            dataTestID="errwidth"
          />
        </>
      );
    },
    getCode: (code: CodeBuilder) => {
      let error_kws: Record<string, string | number> = {};
      if (
        args.spinnerReqiuiresInteraction &&
        args.spinnerValue instanceof ValueTracker &&
        args.spinnerValue.isUpdated
      ) {
        error_kws["linewidth"] = spinnerValue;
      }

      if (args.colorRequiresInteraction && args.value instanceof ValueTracker && args.value.isUpdated) {
        error_kws["color"] = val;
      }
      if (Object.keys(error_kws).length > 0) {
        code.plotAttrs.push(
          `err_kws={${Object.entries(error_kws)
            .map(([key, value]) => `'${key}': ${JSON.stringify(value)}`)
            .join(", ")}}`
        );
      }
    },
  };
}

type GridlineArgs = {
  majorHorizontal: ValueTracker<boolean>;
  majorVertical: ValueTracker<boolean>;
  minorHorizontal: ValueTracker<boolean>;
  minorVertical: ValueTracker<boolean>;
  onChange: (_, event) => void;
  dataTestID?: string;
  multiChart?: boolean;
};

export function GridlinesAttr(args: GridlineArgs): ChartAttr {
  const { majorHorizontal, majorVertical, minorHorizontal, minorVertical, onChange, multiChart = false } = args;

  const gridlines = [
    { label: "Major Horizontal", value: majorHorizontal, key: "majorHorizontal", kind: "major", axis: "y" },
    { label: "Major Vertical", value: majorVertical, key: "majorVertical", kind: "major", axis: "x" },
    { label: "Minor Horizontal", value: minorHorizontal, key: "minorHorizontal", kind: "minor", axis: "y" },
    { label: "Minor Vertical", value: minorVertical, key: "minorVertical", kind: "minor", axis: "x" },
  ];

  const gridlinesValue = gridlines.map((g) => (g.value instanceof ValueTracker ? g.value.value : g.value));

  return {
    component: gridlinesValue[0],
    getReact: (labelWidth: number) => (
      <>
        {gridlines.map(({ label, value, key }) => (
          <Row justifyContent="space-between" key={key}>
            <label className="label" style={{ minWidth: labelWidth }}>
              {label}
            </label>
            <Checkbox
              style={{ width: "100%" }}
              checked={value instanceof ValueTracker ? value.value : value}
              onChange={(_, e) => onChange(key, e)}
              data-testid={args.dataTestID}
            />
          </Row>
        ))}
      </>
    ),
    getCode: (code: CodeBuilder) => {
      const lines = { majorHorizontal, majorVertical, minorHorizontal, minorVertical };
      const generateGridlineUpdate = (gridline, value: string) => {
        const gridCode = `grid(${value}, which='${gridline.kind}', axis='${gridline.axis}')`;
        return multiChart ? `g.${gridCode}` : `ax.${gridCode}`;
      };

      let gridlineUpdates = Object.entries(lines).reduce((updates, [key, value]) => {
        const gridline = gridlines.find((g) => g.key === key);
        if (gridline && value.isUpdated) {
          const isVisible = gridline.value.value ? "True" : "False";
          updates.push(generateGridlineUpdate(gridline, isVisible));
        }
        return updates;
      }, []);

      const minorGridlinesSelected = minorHorizontal.value || minorVertical.value;

      code.plotCalls.push(`default_grid_color = sns.axes_style()["grid.color"]`);
      code.plotCalls.push(`default_label_color = sns.axes_style()["xtick.color"]`);

      if (multiChart) {
        code.plotCalls.push(`for g in ax.axes.flat:
      # Set spines color
      for spine in g.spines.values():
          spine.set_color(default_grid_color)\n
      # Set tick color
      g.tick_params(colors=default_grid_color, labelcolor=default_label_color)\n`);
        if (gridlineUpdates.length > 0) {
          code.plotCalls.push(`for g in ax.axes.flat:
    ${minorGridlinesSelected ? "g.minorticks_on()\n    " : ""}${gridlineUpdates.join("\n    ")}
    g.set_axisbelow(True)
    `);
        }
      } else {
        if (gridlineUpdates.length > 0) {
          gridlineUpdates.push("ax.set_axisbelow(True)");
        }
        code.plotCalls.push(`
# Set spines color
for spine in ax.spines.values():
    spine.set_color(default_grid_color)\n
# Set tick color
ax.tick_params(colors=default_grid_color, labelcolor=default_label_color)\n`);
        code.plotCalls.push(`${minorGridlinesSelected ? "ax.minorticks_on()\n" : ""}${gridlineUpdates.join("\n")}`);
      }
    },
  };
}

type MultiChartBordersArgs = {
  left: boolean;
  right: boolean;
  bottom: boolean;
  top: boolean;
  onChange: (_, event) => void;
  visibleDependencies?: Dependency[];
  enabledDependencies?: Dependency[];
  dataTestID?: string;
  chartType?: string;
};

export function MultiChartBorders(args: MultiChartBordersArgs): ChartAttr {
  const { left, right, bottom, top, onChange } = args;

  const spines = [
    { label: "Top", value: top, key: "topSpine" },
    { label: "Right", value: right, key: "rightSpine" },
    { label: "Bottom", value: bottom, key: "bottomSpine" },
    { label: "Left", value: left, key: "leftSpine" },
  ];

  return {
    component: spines[0].value,
    visibleDependencies: args.visibleDependencies || [],
    enabledDependencies: args.enabledDependencies,
    getReact: (labelWidth: number) => (
      <>
        {spines.map(({ label, value, key }) => (
          <Row key={key} justifyContent="space-between">
            <label key={key} className="label" style={{ minWidth: labelWidth }}>
              {label}
            </label>
            <Checkbox
              key={key}
              style={{ width: "100%" }}
              checked={value}
              onChange={(_, e) => onChange(key, e)}
              data-testid={args.dataTestID}
            />
          </Row>
        ))}
      </>
    ),
    getCode: (code: CodeBuilder) => {
      const spines = { left, bottom, right, top };
      const spineUpdates = Object.entries(spines).reduce((acc, [key, spine]) => {
        const isVisible = spine ? "True" : "False";
        acc.push(`g.spines['${key}'].set_visible(${isVisible})`);
        return acc;
      }, []);

      if (spineUpdates.length > 0) {
        code.plotCalls.push(`
for g in ax.axes.flatten():
    ${spineUpdates.join("\n    ")}
`);
      }
    },
  };
}

type SingleChartArgs = CheckboxArgs & {
  callKey?: string;
  lim?: boolean;
};

export function SingleChartBorders(args: SingleChartArgs): ChartAttr {
  const { value, onChange, label, callKey, lim } = args;
  let val = value instanceof ValueTracker ? value.value : value;
  return {
    component: val,
    visibleDependencies: args.visibleDependencies || [],
    enabledDependencies: args.enabledDependencies,
    getReact: (labelWidth: number) => {
      return (
        <Row justifyContent="space-between">
          <label className="label" style={{ minWidth: labelWidth }}>
            {label}
          </label>
          <Checkbox
            style={{ width: "100%" }}
            checked={val}
            onChange={onChange}
            disabled={!areDependenciesSatisfied(args.enabledDependencies)}
            data-testid={args.dataTestID}
          />
        </Row>
      );
    },
    getCode: (code: CodeBuilder) => {
      if (lim == true && !val) {
        code.plotCalls.push(`ax.spines["${callKey}"].set_position("zero")`);
      } else {
        const sval: string = val ? "True" : "False";
        code.plotCalls.push(`ax.spines["${callKey}"].set_visible(${sval})`);
      }
    },
  };
}

type OriginArgs = CheckboxArgs & {
  yValue?: boolean;
};

export function Origin(args: OriginArgs): ChartAttr {
  const { value, onChange, yValue } = args;
  return {
    component: value as boolean,
    visibleDependencies: args.visibleDependencies || [],
    getReact: (lineWidth: number) => {
      return (
        <Row justifyContent="center">
          <div style={{ minWidth: lineWidth }}></div>
          <Checkbox
            style={{ width: "100%" }}
            checked={yValue ? yValue : (value as boolean)}
            onChange={onChange}
            data-testid={args.dataTestID}
            label={<span style={{ fontWeight: 300 }}>Include Origin</span>}
          />
        </Row>
      );
    },
    getCode: (code: CodeBuilder) => {
      if (value) {
        code.plotCalls.push(`plt.axvline(x=0, color=sns.axes_style()["grid.color"], lw=0.9, zorder=0.5)`);
      }
      if (yValue) {
        code.plotCalls.push(`plt.axhline(y=0, color=sns.axes_style()["grid.color"], lw=0.9, zorder=0.5)`);
      }
    },
  };
}

export function AxesRotation({
  xvalue,
  yValue,
  onChange,
  onYChange,
}: {
  xvalue: ValueTracker<number>;
  yValue: ValueTracker<number>;
  onChange: (val) => void;
  onYChange: (val) => void;
}): ChartAttr {
  return {
    component: xvalue.value,
    getReact: (labelWidth: number) => {
      return [
        { axis: "X", value: xvalue, onChange },
        { axis: "Y", value: yValue, onChange: onYChange },
      ].map((axis) => (
        <Spinner
          key={axis.axis}
          label={`${axis.axis}-Ticks`}
          labelWidth={labelWidth}
          value={axis.value.value}
          onChange={(newValue: number) => axis.onChange(newValue)}
          step={5}
          max={180}
          min={-180}
          units="°"
        />
      ));
    },
    getCode: (code: CodeBuilder) => {
      let plotCode = `for plot in ax.axes.flat:`;
      if (xvalue.isUpdated) {
        plotCode += `\n  plot.set_xticklabels(plot.get_xticklabels(), rotation=${xvalue.value})`;
      }
      if (yValue.isUpdated) {
        plotCode += `\n  plot.set_yticklabels(plot.get_yticklabels(), rotation=${yValue.value})`;
      }
      if (xvalue.isUpdated || yValue.isUpdated) return code.plotCalls.push(plotCode + `\n`);
    },
  };
}
